import Axios from "axios";
import URI from "urijs";
import { toast } from "react-toastify";
import {
  AuthenticationError,
  NetworkError,
  ClientError,
  ServerError,
} from "./error-type";
import { getCookie } from "../utils/cookie-service";

const baseUrl = URI(process.env.REACT_APP_API_URL);
const axios = Axios.create({
  headers: { "Cache-Control": "no-cache" },
});

axios.interceptors.request.use((request) => {
  const token = getCookie("token");
  const companyId = getCookie("hrms-companyId");
  if (token) {
    // request.headers['accesstoken'] = token;
    request.headers.Authorization = `Bearer ${token}`;
    request.headers["X-Company-UUID"] = companyId;
  }
  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error.message === "Network Error") {
      const errorMessages = error.response.data.data;
      if (typeof errorMessages === "object") {
        for (let key in errorMessages) {
          if (errorMessages.hasOwnProperty(key)) {
            toast.error(errorMessages[key], "Validation Error", {
              timeOut: 20000,
              closeButton: true,
              progressBar: true,
            });
          }
        }
      } else {
        toast.error(errorMessages, "Validation Error", {
          timeOut: 20000,
          closeButton: true,
          progressBar: true,
        });
      }
      return Promise.reject(new NetworkError(error.message));
    } else if (error.response.status >= 500) {
      const errorMessages = error.response.data.data;
      if (typeof errorMessages === "object") {
        for (let key in errorMessages) {
          if (errorMessages.hasOwnProperty(key)) {
            toast.error(errorMessages[key], "Validation Error", {
              timeOut: 20000,
              closeButton: true,
              progressBar: true,
            });
          }
        }
      } else {
        toast.error(errorMessages, "Validation Error", {
          timeOut: 20000,
          closeButton: true,
          progressBar: true,
        });
      }
      return Promise.reject(
        new ServerError(error.response.data.error, error.response.status)
      );
    } else if (error.response.status === 401) {
      const errorMessages = error.response.data.data;
      if (typeof errorMessages === "object") {
        for (let key in errorMessages) {
          if (errorMessages.hasOwnProperty(key)) {
            toast.error(errorMessages[key], "Validation Error", {
              timeOut: 20000,
              closeButton: true,
              progressBar: true,
            });
          }
        }
      } else {
        toast.error(errorMessages, "Validation Error", {
          timeOut: 20000,
          closeButton: true,
          progressBar: true,
        });
      }
      return Promise.reject(new AuthenticationError("Unauthorized"));
    } else if (error.response.status === 422) {
      const errorMessages = error.response.data.data;
      if (typeof errorMessages === "object") {
        for (let key in errorMessages) {
          if (errorMessages.hasOwnProperty(key)) {
            toast.error(errorMessages[key], "Validation Error", {
              timeOut: 20000,
              closeButton: true,
              progressBar: true,
            });
          }
        }
      } else {
        toast.error(errorMessages, "Validation Error", {
          timeOut: 20000,
          closeButton: true,
          progressBar: true,
        });
      }
      return Promise.reject(new ClientError(error.response.data.data));
    } else if (error.response.status === 403) {
      const errorMessages = error.response.data.data;
      if (typeof errorMessages === "object") {
        for (let key in errorMessages) {
          if (errorMessages.hasOwnProperty(key)) {
            toast.error(errorMessages[key], "Validation Error", {
              timeOut: 20000,
              closeButton: true,
              progressBar: true,
            });
          }
        }
      } else {
        toast.error(errorMessages, "Validation Error", {
          timeOut: 20000,
          closeButton: true,
          progressBar: true,
        });
      }
      return errorMessages;
    } else if (error.response.status >= 400 && error.response.status < 500) {
      const errorMessages = error.response.data.data;
      if (typeof errorMessages === "object") {
        for (let key in errorMessages) {
          if (errorMessages.hasOwnProperty(key)) {
            toast.error(errorMessages[key], "Validation Error", {
              timeOut: 20000,
              closeButton: true,
              progressBar: true,
            });
          }
        }
      } else {
        toast.error(errorMessages, "Validation Error", {
          timeOut: 20000,
          closeButton: true,
          progressBar: true,
        });
      }
      return Promise.reject(
        new ClientError(
          error.response.data.status || error.response.data,
          error.response.status
        )
      );
    } else if (error.response.status === 429) {
      const errorMessages = error.response.data.data;
      if (typeof errorMessages === "object") {
        for (let key in errorMessages) {
          if (errorMessages.hasOwnProperty(key)) {
            toast.error(errorMessages[key], "Validation Error", {
              timeOut: 20000,
              closeButton: true,
              progressBar: true,
            });
          }
        }
      } else {
        toast.error(errorMessages, "Validation Error", {
          timeOut: 20000,
          closeButton: true,
          progressBar: true,
        });
      }
    }
    return Promise.reject({ ...error });
  }
);

const httpService = {
  async get(payload) {
    const url = `${decodeURIComponent(URI(baseUrl).segment(payload.action))}`;
    if (payload.segment) url.segment(payload.segment.toString());

    if (payload.query) url.search(payload.query);
    return axios.get(url.toString());
  },

  async post(payload) {
    let url;
    //if url comming in as a object url like paramsQeury
    if (payload.action instanceof Object) {
      url = payload.action.url;
    } else {
      url = URI(baseUrl).segment(payload.action);
    }
    const headers = { "Content-Type": "application/json" };
    if (payload.formData)
      headers["headers"] = { "Content-Type": "multipart/form-data" };
    return axios.post(
      url.toString(),
      payload.data || payload.formData,
      headers
    );
  },

  async put(payload) {
    const url = URI(baseUrl).segment(payload.action);
    const headers = { "Content-Type": "x-www-form-urlencoded" };
    if (payload.formData)
      headers["headers"] = { "Content-Type": "multipart/form-data" };

    return axios.put(url.toString(), payload.data, headers);
  },
  async delete(payload) {
    const url = URI(baseUrl).segment(payload.action);
    return axios.delete(url.toString(), { data: payload.data });
  },
};

function getUrl(action) {
  action = action.split("/")[0];
  switch (action) {
    case "employee":
      return baseUrl.toString().replace("8080", "9001");
    case "attendance":
      return baseUrl.toString().replace("8080", "9002");
    default:
      return baseUrl.toString().replace("8080", "9000");
  }
}
export default httpService;
