const routes = {
  home: "/",
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  privacyPolicy: "/privacy-policy",
  termsOfService: "/terms-of-service",
  dashboard: "/",

  attendance: {
    attendanceList: "/attendance",
    attendanceLogList: "/attendance/punch-attendance-log-list",
    attendanceLog: "/attendance/punch-attendance-log",
    LogImport: "/attendance/log-import",
    autoAttendance: "/attendance/auto-attendance",
    monthlyLeavereport: "/attendance/monthly-leave-report",
  },
  employee: {
    listEmployee: "/employee",
    addEmployee: "/employee/add",
    listEmployeeNew: "/employee",
    addEmployeeNew: "/employee/add",
  },
  // employee confirmation
  employeeConfirmation: {
    listEmployeeConfirm: "/employee-confirmation",
  },
  //salary process
  salaryProcess: {
    salaryProcess: "/salary-process",
    processList: "/process-list",
  },

  // salary sheet
  salarySheet: {
    salarySheetList: "/salary-sheet/list",
    salarySheetSetting: "/salary-sheet-setting/list",
    salarySheetSettingAdd: "/salary-sheet-setting/add",
    salarySheetSettingEdit: "/salary-sheet-setting/edit",
    salarySheetSettingReport: "/salary-sheet-setting/report",
  },
  // master
  master: {
    addCompany: "/masters/company/add",
    listCompany: "/masters/company",
    addCompanyNew: "/masters/company-new/add",
    listCompanyNew: "/masters/company-new",
    qualification: "/masters/qualification",
    country: "/masters/country",
    state: "/masters/state",
    currency: "/masters/currency",
    gender: "/masters/gender",
    project: "/masters/project",
    grade: "/masters/grade",
    division: "/masters/division",
    divisionAdd: "/masters/division/add",
    divisionEdit: "/masters/division/edit",
    designation: "/masters/designation",
    department: "/masters/department",
    category: "/masters/category",
    branch: "/masters/branch",
    branchAdd: "/masters/branch/add",
    branchEdit: "/masters/branch/edit",
    bank: "/masters/bank",
    prefixName: "/masters/prefix-name",
    payrollCycle: "/masters/payroll-cycle",
    idProof: "/masters/id-proof",
    documentType: "/masters/document-type",
    costCenter: "/masters/cost-center",
    skills: "/masters/skills",
    location: "/masters/location",
    locationAdd: "/masters/location/add",
    locationEdit: "/masters/location/edit",
    bonus: "/masters/bonus",
    bonusCategory: "/masters/bonus-category",
    essentials: "/masters/essentials",
    source: "/masters/attandance-source",
    cron: "/masters/cron",
  },
  // leave
  leave: {
    leaveType: "/attendance/leave-type",
    leaveSetting: "/attendance/leave-setting",
    addLeaveSetting: "/attendance/leave-setting/add",
    editLeaveSetting: "/attendance/leave-setting/edit",
    holidayType: "/attendance/holiday-type",
    holiday: "/attendance/holiday",
    manualLeave: "/attendance/manual-leave",
    leaveApplication: "/attendance/leave-application",
    leaveReport: "/attendance/leave-report",
  },
  // shift
  shift: {
    list: "/shifts",
    addShift: "/shifts/add",
    shiftAssign: "/shifts/assigned",
    addShiftAssign: "/shifts/assigned/add",
  },
  // assets
  assets: {
    list: "/assets/list",
    group: "/assets/group/list",
    requisitionList: "/assets/requisition/list",
    requestList: "/assets/request/list",
    movementList: "/assets/movement/list",
    checkIssuedAssetsList: "/assets/check-issued-assets/list",
    uploadDocuments: "/assets/upload/documents",
  },
  // exitProcess
  exit: {
    exitType: "/exit/exit-type",
    exitClearanceDepartment: "/exit/clearance-department",
    exitClearanceDepartmentAdd: "/exit/clearance-department/add",
    // exitClearanceDepartmentAdd:"/exit/clearance-department/add/:id",
    noticeDaysSetting: "/exit/notice-days-setting",
    interviewIncharge: "/exit/interview-incharge",
    addInterViewIncharge: "/exit/interview-incharge/add",
    departmentClearance: "/exit/department-clearance",
    interviewQuestions: "/exit/interview-questions",
    addInterviewQuestions: "/exit/interview-questions/add",
  },
  // exitFull & Final
  exitfullfinal: {
    exitTypeFullAndFinal: "/exit/exit-full-and-final",
    exitTypeFullAndFinalFilter: "/exit/exit-full-and-final-filter",
    exitTypeFullAndFinalProcess: "/exit/exit-full-and-final-process",
  },

  // Query Management
  queryManagement: {
    queryManagementList: "/query/query-management-list",
    queryManagementAdd: "/query/query-management/add",
  },

  // OnBoarding Management
  onBoarding: {
    onBoardingDashboard: "/onboarding/dashboard",
    letterTemplate: "/onboarding/latter-template",
    assignLetter: "/onboarding/assign-letter",
    offeredList: "/onboarding/offered-list",
    offeredEdit: "/onboarding/offered-edit",
    offeredAdd: "/onboarding/offered-add",
    offeredSalaryStructure: "/onboarding/offered-salary-structure",
    offeredAcceptCandidateList: "/onboarding/offered-accepted-list",
    employeeAcceptedDetails: "/onboarding/employee-accepeted-details/add",
    onBoardingApprover: "/onboarding/onboarding-approver",
  },

  // reimbursement
  reimbursement: {
    travelBudgetPolicy: "/travel-budget-policy",
    travelBudgetPolicyAdd: "/travel-budget-policy/add",
    travelBudgetPolicyEdit: "/travel-budget-policy/edit",
    travelBudgetPolicyView: "/travel-budget-policy/view",
    approvalMatrix: "/approval-matrix",
    approvalMatrixEdit: "/approval-matrix/edit",
    approvalMatrixView: "/approval-matrix/view",
    approvalMatrixForm: "/approval-matrix-form",
    sittingFaceWindow: "/sitting-face-list",
    sittingFaceWindowEdit: "/sitting-face-list/edit",
    sittingFaceWindowView: "/sitting-face-list/view",
    sittingFaceWindowForm: "/sitting-face-form",
    accuredBalancedDetails: "/accured-balanced-details",
    accuredSelectedDetails: "/accured-selected-details",
    checkAccuralSelectedDetails: "/check-accural-selected-details",
    checkAccuralDetails: "/check-accural-details",
    processAdminReimbursement: "/process-admin-reimbursement",
    processAdminReimbursementAdd: "/process-admin-reimbursement/add",
    processAdminReimbursementView: "/process-admin-reimbursement/view",
    processAdminReimbursementReport: "/process-admin-reimbursement/report",
  },

  // users
  users: {
    admin: {
      userList: "/admin/user/list",
      roleList: "/admin/role/list",
      adminRoleCreate: "/admin/admin-role-create",
    },
    ess: {
      userList: "/ess/user/list",
      roleList: "/ess/role/list",
      assignRoles: "/ess/role/assign-roles",
      essRoleCreate: "/ess/roles",
      mobileRights: "/ess/user-access",
    },
  },

  // payroll-old
  // payroll: {
  //   salaryHead: "/payroll/salary-head",
  //   deductionHead: "/payroll/deduction-head",
  //   otherHeadAndLinkHead: "/payroll/other-head-and-link-head",
  //   branchMaster: "/payroll/branch-master",
  //   divisionMaster: "/payroll/division-master",
  //   locationMaster: "/payroll/location-master",
  //   departmentMaster: "/payroll/department-master",
  //   gradeMaster: "/payroll/grade-master",
  //   categoryMaster: "/payroll/category-master",
  //   designationMaster: "/payroll/designation-master",
  //   costCenter: "/payroll/cost-center",
  //   bankMaster: "/payroll/bank-master",
  //   projectMaster: "/payroll/project-master",
  //   employeeDocumentMaster: "/payroll/employee-document-master",
  //   bonusCategory: "/payroll/bonus-category",
  //   leaveEncashment: "/payroll/leave-encashment",
  //   ctcComponentSelection: "/payroll/ctc-component-selection",
  //   gratuityCalculation: "/payroll/gratuity-calculation",
  //   salaryStructure: "/payroll/salary-structure",
  //   promotionAndTransfer: "/payroll/promotion-and-transfer",
  //   employeeIncrement: "/payroll/employee-increment",
  //   journalVoucherSettings: "/payroll/journal-vouche-settings",
  //   incrementArrear: "/payroll/increment-arrear",
  //   lwpArrear: "/payroll/lwp-arrear",
  //   otherArrear: "/payroll/other-arrear",
  //   bonusCalculation: "/payroll/bonus-calculation",
  // },

  // payroll
  payroll: {
    // earning
    TypeOfHead: "/payroll/type-of-head",
    EarningSalaryHead: "/payroll/salary-head",
    EarningSalaryHeadAdd: "/payroll/salary-head/add",
    EarningSalaryHeadEdit: "/payroll/salary-head/edit",
    EarningSalaryHeadView: "/payroll/salary-head/view",
    // Deducation
    DeducationHead: "/payroll/deducation-head",
    DeducationHeadAdd: "/payroll/deducation-head/add",
    DeducationHeadEdit: "/payroll/deducation-head/edit",
    DeducationHeadView: "/payroll/deducation-head/view",
    // other
    OtherHead: "/payroll/other-head",
    OtherHeadAdd: "/payroll/other-head/add",
    OtherHeadEdit: "/payroll/other-head/edit",
    OtherHeadView: "/payroll/other-head/view",
    // Gl code
    GlCodeSetting: "/payroll/gl-code-setting",

    // overtime
    OverTime: "/payroll/over-time",
    OverTimeUploadAttandance: "/payroll/over-time/upload-attandance",
    OverTimeTransferOt: "/payroll/over-time/transfer-ot",
    LwfRuleSetting: "/payroll/lwf-rule-setting",
    LwfRuleSettingAdd: "/payroll/lwf-rule-setting/add",
    LwfRuleSettingEdit: "/payroll/lwf-rule-setting/edit",
    UploadOtAttendance: "/payroll/upload-ot-attendance",
    ProcessAll: "/payroll/process-all",
    SummaryReport: "/payroll/summary-report",
    DetailsReport: "/payroll/details-report",

    // Add CTC Component settings
    CtcComponentSetting: "/payroll/ctc-component-setting",
    CtcComponentSettingAdd: "/payroll/ctc-component-setting/add",
    CtcComponentSettingEdit: "/payroll/ctc-component-setting/edit",
    CtcComponentSettingView: "/payroll/ctc-component-setting/view",
    CtcComponentSettingReport: "/payroll/ctc-component-setting/reports",

    // Professiona Tax
    ProfessionalTax: "/payroll/professional-tax",
    ProfessionalTaxAdd: "/payroll/professional-tax/add",
    ProfessionalTaxEdit: "/payroll/professional-tax/edit",

    // Increment Arrear
    IncrementArrear: "/payroll/increment-arrear",
    IncrementArrearAdd: "/payroll/increment-arrear/add",
    IncrementArrearEdit: "/payroll/increment-arrear/edit",

    // Leave Encashment
    LeaveEncashment: "/payroll/leave-encashment",
    LeaveEncashmentReport: "/payroll/leave-encashment-report",
    LeaveEncashmentNotAddBtn: "/payroll/leave-encashment-not-add-btn",

    // Attandance Report
    AttandanceReport: "/payroll/attandance-report",
    AttandanceReportView: "/payroll/attandance-report-view",

    // salary Structure
    SalaryStructure: "/payroll/salary-structure",
    SalaryStructureAdd: "/payroll/salary-structure/add",
    SalaryStructureView: "/payroll/salary-structure/view",

    BonusCalculation: "/payroll/bonus-calculation",
    BonusCalculationProcess: "/payroll/bonus-calculation-process",
    BonusCalculationSummary: "/payroll/bonus-calculation-summary-report",
    BonusCalculationDetails: "/payroll/bonus-calculation-details-report",

    LwpArrear: "/payroll/lwp-arrear",
    LwpArrearCalculation: "/payroll/lwp-arrear/calculation/add",
    LwpArrearCalculationEdit: "/payroll/lwp-arrear/calculation/edit",

    OtherArrear: "/payroll/other-arrear",
    OtherArrearCalculation: "/payroll/other-arrear/calculation",
    OtherArrearCalculationEdit: "/payroll/other-arrear/calculation/edit",

    GrautityCalculation: "/payroll/grautity-calculation",
    PromotionAndTransfer: "/payroll/promotion-and-transfer",
    EmployeeIncrement: "/payroll/employee-increment",
    EmployeeIncrementAdd: "/payroll/employee-increment/add",
    EmployeeIncrementEdit: "/payroll/employee-increment/edit",

    // Payroll Increment More Than One
    ArrearBaiseList: "/payroll/arrear-baise-increment-list",
    ArrearBaiseMoreThanOne:
      "/payroll/arrear-baise-increment/more-than-one-list",
    ArrearBaiseView: "/payroll/arrear-baise-increment/view",
  },

  // monhtlyCalculation
  monthlyCalculation: {
    monthlyCalculation: "/monthly/earning-variables",
    deductionVariable: "/monthly/deduction-variables",
  },

  // loan & Advanced
  loanAndAdvance: {
    dashboard: "/loan/loan-dashboard",
    loanAndPolicyList: "/loan/loan-policy",
    loanAndAdvanceSettings: "/loan/loan-settings",
    loanAndPolicyList: "/loan/loan-policy",
    loanAndAdvanceSettings: "/loan/loan-settings",
    loanDisbursementList: "/loan/loan-disbursement/list",
    loanDisbursementView: "/loan/loan-disbursement/view",
    loanFinanceList: "/loan/loan-finance/list",
    loanFinanceView: "/loan/loan-finance/view",
    loanReport: "/loan/loan-report",
  },

  // TDS Routes
  tds: {
    TDSSettings: "/tds/tds-settings",
    TDSUSVIAItems: "/tds/us-vi-a-items",
    TDSPerksMaster: "/tds/perks-master",
    TDSIncomeArrearExemption: "/tds/income-arrear-exemption",
    TDSBaseWindow: "/tds/base-window",
    TDSEmployeeProofChecking: "/tds/employee-proof-checking-window",
    TDSChallan: "/tds/tds-return-process/tds-challan",
    TDSCompanyDetails: "/tds/tds-return-process/company-details",
    TDSChallanDetails: "/tds/tds-return-process/challan-details",
    TDSChallanReport: "/tds/tds-challan-report",
    TDSSalryDetail: "/tds/tds-salary-details",
    TDSperquisite: "/tds/tds-perquisite-view",
  },

  //mail
  mail: {
    mailSettting: "/mail/mail-setting",
    ThirdPartymailSettting: "/mail/third-party-mail-setting",
    mailer: "/mail/mailer",
    emailTemplate: "/mail/email-template",
  },

  //controls

  controls: {
    uploadControl: "/controls/uplaod-control",
    announcement: "/controls/announcement",
    policies: "/controls/policies",
    news: "/controls/news",
    eventGallery: "/controls/events-gallery",
    approvalSetting: "/controls/approval-setting",
    importUtility: "/controls/import-utility",
    softwareLogReport: "/controls/software-log-report",
  },

  selectMonths: "/admin/select-month",

  // =============================================== ess-part==============================================
  ess: {
    dashboard: "/",
    profile: "/ess/profile",
    employeeEdit: "/ess/profile/employee-details-edit",
    statutoryEdit: "/ess/profile/statutory-edit",
    personalDetailsEdit: "/ess/profile/personal-details-edit",
    qualificationEdit: "/ess/profile/qualification-edit",
    familymembersEdit: "/ess/profile/family-members-edit",
    // attendance & leaves
    attendance: {
      markAttendance: "/ess/attendance/mark-attendance",
      leaveApplication: "/ess/attendance/leave-application",

      leaveApproval: "/ess/attendance/leave-approval",
      missedPunch: "/ess/attendance/missed-punch",
      missedPuchApprover: "/ess/attendance/missed-punch-approver",
      leaveAttendanceDetails: "/ess/attendance/leaveAttendanceDetails",
    },

    // assets
    assets: {
      request: "/ess/asstes/request",
      list: "/ess/my-asstes/list",
      assetsApprovalList: "/ess/assets/manager-approval-list",
    },
    // Exit Process
    essExitProcess: {
      employeeExitApplication: "/ess/exit/employee-application",
      employeeExitApproval: "/ess/exit/employee-approval",
      selfExitClearance: "/ess/exit/self-exit-clearance",
      selfExitClearanceApproval: "/ess/exit/self-exit-approval",
      exitInterviewEmail: "/ess/exit/interview-email",
      approvalExitInterview: "/ess/exit/approval-exit-interview",
      supervisorExitClearance: "/ess/exit/supervisor-exit-clearance",
      viewExitInterView: "/ess/exit/view-exit-interview",
      viewExitInterViewTab: "/ess/exit/view-exit-interview-tab",
      viewExitInterViewApprovalTab:
        "/ess/exit/view-exit-interview-approval-tab",
    },

    essShift: {
      essEmployeeShiftList: "/ess/shift/employee-list",
      essEmployeeShiftAssign: "/ess/shift/employee-shift-assign",
    },

    essOnBoarding: {
      onBoardingList: "/ess/onboarding/onboarding-management-list",
      onBoardingCtc: "/ess/onboarding/onboarding-ctc",
    },

    essReimbursementProcess: {
      travelRequestList: "/ess/reimbursement/travel-request-list",
      travelRequestAdd: "/ess/reimbursement/travel-requisition/add",
      travelRequestEdit: "/ess/reimbursement/travel-requisition/edit",
      travelRequestView: "/ess/reimbursement/travel-requisition/view",
      travelRequestApprovalList:
        "/ess/reimbursement/travel-request-approval-list",
      travelRequestApprovalAdd:
        "/ess/reimbursement/travel-approval-requisition/add",
      hrAdminApprovalList: "/ess/reimbursement/hr-admin-approval-list",
      hrAdminApprovalAdd:
        "/ess/reimbursement/hr-admin-approval-requisition/add",
      financeDepartmentList: "/ess/reimbursement/finance-department-list",
      financeDepartmentAdd:
        "/ess/reimbursement/finance-department-approval-requisition/add",
      otherLtaTravelList: "/ess/reimbursement/otherlta/travel-employee-list",
      otherLtaTravelAdd: "/ess/reimbursement/otherlta/travel-employee/add",
      otherLtaTravelEdit: "/ess/reimbursement/otherlta/travel-employee/edit",
      otherLtaTravelView: "/ess/reimbursement/otherlta/travel-employee/view",
      otherLtaApprovedList:
        "/ess/reimbursement/otherlta/approved-employee-list",
      otherLtaApprovedAdd: "/ess/reimbursement/otherlta/approved-employee/add",
      otherLtaFinanceApprovedList:
        "/ess/reimbursement/otherlta/finance-approved-employee-list",
      otherLtaFinanceApprovedAdd:
        "/ess/reimbursement/otherlta/finance-approved-employee/add",
      ltaClaimList: "/ess/reimbursement/otherlta/lta-claim-list",
      ltaClaimAdd: "/ess/reimbursement/otherlta/lta-claim/add",
      ltaClaimEdit: "/ess/reimbursement/otherlta/lta-claim/edit",
      ltaClaimView: "/ess/reimbursement/otherlta/lta-claim/view",
      ltaClaimApprovedList:
        "/ess/reimbursement/otherlta/lta-claim-approved-list",
      ltaClaimApprovedAdd: "/ess/reimbursement/otherlta/lta-claim-approved/add",
      ltaClaimFinanceApprovedList:
        "/ess/reimbursement/otherlta/lta-claim-finance-approved-list",
      ltaClaimFinanceApprovedAdd:
        "/ess/reimbursement/otherlta/lta-claim-finance-approved/add",
      travelClaimWindowList:
        "/ess/reimbursement/otherlta/travel-claim-window-list",
      travelClaimWindowAdd:
        "/ess/reimbursement/otherlta/travel-claim-window/add",
      travelClaimWindowEdit:
        "/ess/reimbursement/otherlta/travel-claim-window/edit",
      travelClaimWindowView:
        "/ess/reimbursement/otherlta/travel-claim-window/view",
      travelClaimWindowApproverList:
        "/ess/reimbursement/otherlta/travel-claim-window-approver-list",
      travelClaimWindowApproverAdd:
        "/ess/reimbursement/otherlta/travel-claim-window-approver/add",
      travelPaymentProcessList:
        "/ess/reimbursement/otherlta/travel-payment-process-list",
      travelPaymentProcessAdd:
        "/ess/reimbursement/otherlta/travel-payment-process/add",
    },

    essTdsCalculationProcess: {
      tdsCalculation: "/ess/tds/tds-calculation",
      tdsApprover: "/ess/tds/tds-approver",
      tdsProofChecking: "/ess/tds/proof-checking",
    },
    queryManagement: {
      queryManagement: "/ess/queryManagement",
    },
    loan: {
      loanApply: "/ess/loan/loan-apply",
      loanApprove: "/ess/loan/loan-approve",
      loanApproverView: "/ess/loan/loan-approve/view",
      loanFinanceApprover: "/ess/loan/loan-finance",
      loanFinanceView: "/ess/loan/loan-finance/view",
    },
    loan: {
      loanApply: "/ess/loan/loan-apply",
      loanApprove: "/ess/loan/loan-approve",
      loanApproverView: "/ess/loan/loan-approve/view",
      loanFinanceList: "/ess/loan/loan-finance/list",
      loanFinanceApprover: "/ess/loan/loan-finance",
      loanFinanceView: "/ess/loan/loan-finance/view",
      loanReport: "/ess/loan/loan-report",
      loanApprovalReport: "/ess/loan/loan-approval-report",
      loanApprovalViewReport: "/ess/loan/loan-approval-report/view",
    },
  },
  // =============================================== On Boarding Outer-part==============================================
  onBoardingOuterEmployee: {
    employeeDetails: "/onboarding/employee-details/add",
  },
};
export default routes;
